@font-face {
  font-family: 'Bicyclette';
  src:
    url('../assets/font/Bicyclette-Regular.woff2') format('woff2'),
    url('../assets/font/Bicyclette-Regular.woff') format('woff'),
    url('../assets/font/Bicyclette-Regular.ttf') format('truetype');
}

body {
  font-family: 'Bicyclette', sans-serif !important;
}

/*
  * Material icons font file
  * Refer here for getting icons - https://fonts.google.com/icons
*/
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v7/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOej.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
