* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--primary-color);
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield; /* Chrome, Safari, Edge */
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Standard */
}
