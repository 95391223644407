:root[data-theme='theme-light'] {
  --brand-primary: #ffad00;
  --brand-secondary: #5e5661;
  --neutral-neutral-2: #5e5e5e;
  --neutral-neutral-3: #ababab;
  --neutral-neutral-5: #ffffff;
  --alert: #d90000;
  --semantics-info: #0061f3;
  --text-color: #fff;
  --text-black: #000;
  --text-lightblack: #262626;
  --scrollbar-color: #d9d9d9;
}

:root[data-theme='theme-dark'] {
  --primary-color: #49afd9;
  --text-color: #e3f5fc;
  --bg-color: #1b2a32;
  --component-bg-color: #22343c;
}
