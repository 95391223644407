@mixin label-style {
  color: var(--Neutral-Neutral-2, #5e5e5e);
  font-family: "Bicyclette";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0px;
}

.tracking {
  display: block;
  margin: 50px auto;

  & > .__heading {
    color: var(--brand-secondary, #5e5661);
    font-family: "Bicyclette";
    font-size: 34px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
  }

  & > .__search {
    display: flex;
    margin-top: 50px;

    & > input {
      width: 100%;
      border: 1px solid var(--Neutral-Neutral-2, #5e5e5e);
      background-color: transparent;
      color: var(--Neutral-Neutral-1, #262626);
      font-family: "Bicyclette";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.2px;
      padding: 10px 20px;
      border-radius: 10px 0px 0px 10px;
      border-right: none;
      outline: none;
    }

    & > button {
      border: 1px solid var(--Neutral-Neutral-2, #5e5e5e);
      background-color: #ffad00;
      color: var(--Neutral-Neutral-1, #262626);
      font-family: "Bicyclette";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.2px;
      padding: 10px 20px;
      border-radius: 0px 10px 10px 0px;
      border-left: none;
      cursor: pointer;
    }
  }

  & > .__error {
    color: red;
    font-family: "Bicyclette";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.2px;
    margin-top: 20px;
    text-align: center;
  }

  & > .__body {
    margin-top: 50px;

    & > .tracking-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & > .track {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & > .details {
          & > .label {
            @include label-style();
          }

          & > .value {
            color: var(--Neutral-Neutral-1, #262626);
            font-family: "Bicyclette";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.2px;
            margin-top: 10px;
            margin-bottom: 0px;
          }
        }

        @media (max-width: 480px) {
          flex-direction: row;
          gap: 50px;
        }
      }

      & > .pick-drop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 100px;

        & > .pick,
        .drop {
          & > .label {
            @include label-style();
          }

          & > .pickup-partner,
          .drop-partner {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 4px 20px 0px rgba(5, 5, 5, 0.08);
            margin-top: 10px;
          }
        }

        @media (max-width: 786px) {
          gap: 50px;
        }
      }

      & > .expected-delivery {
        display: flex;
        justify-content: end;
        flex-direction: column;

        & > .label {
          @include label-style();
          text-align: right;

          @media (max-width: 786px) {
            text-align: left;
          }
        }

        & > .day {
          color: var(--Brand-Primary, #ffad00);
          font-family: "Bicyclette";
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: -0.5px;
          margin-top: 10px;
          margin-bottom: 0px;
          text-align: right;

          @media (max-width: 786px) {
            text-align: left;
          }
        }
      }

      @media (max-width: 786px) {
        flex-wrap: wrap;
        gap: 25px;
      }
    }

    & > .status-bar {
      margin: 60px 95px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;

      & > .active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        & > .status-label {
          color: var(--Neutral-Neutral-1, #262626);
          font-family: "Bicyclette";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.2px;
          margin: 0px;
        }

        & > .status-date {
          color: var(--Neutral-Neutral-2, #5e5e5e);
          font-family: "Bicyclette";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: -0.2px;
          margin: 0px;
        }
      }

      & > .pending {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        & > .dot {
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background-color: var(--Neutral-Neutral-3, #ababab);
        }

        & > .status-label {
          color: var(--Neutral-Neutral-2, #5e5e5e);
          font-family: "Bicyclette";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.2px;
          margin: 0px;
        }
      }

      & > .arrow:last-child {
        display: none;
      }

      @media (max-width: 1024px) {
        margin: 60px 30px;
      }

      @media (max-width: 786px) {
        flex-wrap: wrap;
        gap: 25px;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }

    & > .tracking-view {
      margin-top: 50px;
      border-radius: 20px;
      background: var(--Neutral-Neutral-5, #fff);
      box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.08);

      & > .title {
        background: var(--Brand-Primary, #ffad00);
        text-align: center;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        color: var(--Brand-Secondary, #5e5661);
        font-family: "Bicyclette";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.2px;
        padding: 10px 0px;
        margin: 0px;
        margin-bottom: 40px;
      }

      & > .details {
        padding-bottom: 40px;

        & > .track-detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 13px 40px;
          border-bottom: 0.5px solid var(--Brand-Secondary, #5e5661);
          cursor: pointer;

          & > .track-information {
            display: flex;
            align-items: center;
            gap: 10px;

            & > .date {
              color: var(--Brand-Secondary, #5e5661);
              font-family: "Bicyclette";
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: -0.2px;
              margin: 0px;

              @media (max-width: 480px) {
                font-size: 18px;
              }
            }

            & > .action {
              color: var(--Brand-Secondary, #5e5661);
              font-family: "Bicyclette";
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.2px;
              margin: 0px;
            }
          }

          & > .vector {
            cursor: pointer;
          }

          @media (max-width: 480px) {
            padding: 13px 20px;
          }
        }

        & > .track-update {
          padding: 0px 40px;

          & > .update-msg {
            display: flex;
            margin-top: 20px;

            & > .time {
              color: var(--Neutral-Neutral-2, #5e5e5e);
              font-family: "Bicyclette";
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: -0.2px;
              margin: 0px;
              margin-right: 20px;

              @media (max-width: 480px) {
                margin-right: 0px;
              }
            }

            & > .location {
              color: var(--Neutral-Neutral-2, #5e5e5e);
              font-family: "Bicyclette";
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.2px;
              margin: 0px;
            }

            @media (max-width: 480px) {
              flex-direction: column;
              gap: 10px;
            }
          }

          @media (max-width: 480px) {
            padding: 0px 20px;
          }
        }
      }

      @media (max-width: 480px) {
        margin: 40px 0px 80px;
      }
    }
  }

  @media (max-width: 1024px) {
    margin: 80px;
  }

  @media (max-width: 480px) {
    margin: 40px;
  }
}
